import API from "@/api";

export default {
  add: (data) => {
    return API.requestPost({
      data,
      url: "api/v3/really/add"
    });
  },
};
