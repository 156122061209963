<template>
  <div class="continer">
    <div class="header">
      <h1 class="title">真假财务</h1>
    </div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!--查询栏-->
        <el-row :gutter="10">
          <el-col :span="3">
            <el-input v-model="name" size="medium" placeholder="请输入姓名"></el-input>
          </el-col>
          <el-col :span="1">
            <el-button type="primary" size="medium" icon="el-icon-search" @click="search">查询</el-button>
          </el-col>
        </el-row>
      </div>
      <div v-if="add">
        <!--添加凭证和评价-->
        <el-row class="oprate" :gutter="10">
          <el-col :span="2">
            <!-- <el-button type="primary" size="medium" @click="upload">上传凭证({{ process }})</el-button> -->
            <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
              :on-remove="handleRemove" multiple :limit="5" :on-change="handleChange">
              <el-button size="medium" type="primary">上传凭证({{ fileList.length }}/5)</el-button>
            </el-upload>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" size="medium" @click="evaluate">我的评价</el-button>
          </el-col>
        </el-row>

        <!--投票-->
        <el-row :gutter="12">
          <el-col :span="12">
            <el-card shadow="hover">
              <div slot="header">
                <el-row>
                  <el-col :span="22">
                    <h1 class="title">姓名 {{ name }}</h1>
                  </el-col>
                  <el-col :span="2">
                    <h1>评价</h1>
                  </el-col>
                </el-row>
              </div>
              <!--单选投票类型-->
              <el-row class="radio-type" v-for="it in data.typeList" :key="it.key">
                <el-col :span="12">
                  <el-radio v-model="type" :label="it.key" @input="change">{{ it.value }}</el-radio>
                </el-col>
                <el-col :span="10">
                  <el-progress
                    :percentage="it.count == 0 ? 0 : total == 0 ? 100 : Math.round(it.count * 100 / total)"></el-progress>
                </el-col>
              </el-row>
              <el-button style="margin-top: 20px;" type="primary" size="medium" @click="submit">确认投票</el-button>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="hover">
              <div slot="header">
                <h1 class="title">评价记录</h1>
              </div>
              <el-empty v-if="data.evaluateList.length == 0" description="描述文字"></el-empty>
              <el-tag class="tag-row" v-else v-for="(it, index) in data.evaluateList" :key="index" type="info"
                effect="plain">
                昵称：{{ it.name }}｜{{ it.content }}
              </el-tag>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <!--填写评价内容弹窗-->
    <el-dialog :visible.sync="dialogVisible" :center="true" :append-to-body="true" width="25%">
      <el-form :model="form">
        <el-form-item label="昵称" :label-width="formLabelWidth">
          <el-input type="text" maxlength="10" show-word-limit v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="评价内容" :label-width="formLabelWidth">
          <el-input type="textarea" maxlength="50" show-word-limit v-model="form.content" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEvaluate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import service from './api'
export default {
  data () {
    return {
      name: null,
      add: false,
      data: {
        typeList: [{ key: 0, value: '真财务', count: 0 }, { key: 1, value: '假财务', count: 0 },
        { key: 2, value: '操作手', count: 0 }, { key: 3, value: '撸子', count: 0 }, { key: 4, value: '刷子', count: 0 }],
        evaluateList: []
      },
      total: 0,
      type: null,
      selectedType: null,
      fileList: [],
      dialogVisible: false,
      formLabelWidth: '120px',
      form: {}
    }
  },
  methods: {
    // 查询对象
    search () {
      this.$confirm('没有查到此人, 是否添加此人?', '提示', {
        confirmButtonText: '添加评价',
        showCancelButton: false,
        showClose: false,
        type: 'warning',
        center: true
      }).then(() => {
        service.add({name:this.name}).then((res)=>{
          this.add = true
        })
      }).catch(() => {
      });
    },
    // 评价对象
    evaluate () {
      this.dialogVisible = true
      this.form = {}
    },
    saveEvaluate () {
      this.dialogVisible = false
      this.data.evaluateList.push({ ...this.form })
    },
    change (val) {
      if (this.selectedType != null) {
        this.data.typeList[this.selectedType].count--
      }
      this.selectedType = val
      this.data.typeList[val].count++
      this.total = this.data.typeList.reduce((total, obj) => {
        return total + obj.count;
      }, 0)
    },
    // 上传凭证开始
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    handleRemove (file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 上传凭证结束
    submit () {
      this.data.completed = true
      this.$confirm('确认提交投票?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => {
      })
    }
  }
}

</script>
<style lang="scss">
.header {
  margin-bottom: 20px;
}

.continer {
  width: 100%;
  height: 100%;
}

.title {
  font-weight: bold;
  color: black;
}

.box-card {
  width: 100%;
  height: 100%;
}

.oprate {
  margin: 0 0 20px 0px;
}

.radio-type {
  margin: 15px 15px 15px 0px;
}

.el-card__body {
  padding: 20px;
  height: 393px;
}

.tag-row {
  margin: 5px;
}
</style>